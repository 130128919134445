import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import Seo from '../components/seo';
import { Box, Heading, Anchor, ResponsiveContext, Button } from 'grommet';
import designerIllustration from '../images/illustrations/designer.svg';
import coderIllustration from '../images/illustrations/coding_3.svg';
import smartHomeIllustration from '../images/illustrations/smart_home.svg';
import kiteboardingIllustration from '../images/illustrations/kiteboarding.svg';
import travelIllustration from '../images/illustrations/road_trip.svg';
import electronicEngineerIllustration from '../images/illustrations/electronic_engineer.svg';
import Layout from '../components/Layout';
import { getThemeColor } from '../theme';
import styled from 'styled-components';

const StyledImg = styled(StaticImage)`
  display: flex !important;
  width: 100% !important;
`;

const GatsbyLink = styled(Link)`
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;
  color: ${getThemeColor('brand')};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover {
    text-decoration: underline;
  }
`;

const AboutMePage = () => {
  const screenSize = React.useContext(ResponsiveContext);

  return (
    <Layout>
      <Seo title="About me" />
      <Box
        align="stretch"
        direction="column"
        justify="center"
        alignContent="center"
        css={`
          background: ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-light-gray')};
        `}
      >
        <Box
          width="xxlarge"
          justify="end"
          alignSelf="center"
          direction="row"
          margin={{ vertical: '0', horizontal: 'auto' }}
          align="center"
          alignContent="center"
        >
          {screenSize !== 'small' && (
            <Box width="50%">
              <StaticImage
                  src="../images/lukas_horak_front_dark_hires.png"
                  alt="Lukas Horak"
                  loading='lazy'
                  placeholder='blurred'
                />
            </Box>
          )}

          <Box
            width={screenSize === 'small' ? 'full' : '50%'}
            pad={{ horizontal: 'xlarge', vertical: 'small' }}
            align="center"
          >
            {screenSize === 'small' && (
              <Box width="medium" alignContent="center">
                <StaticImage
                  src="../images/lukas_horak_front_dark_hires.png"
                  alt="Lukas Horak"
                  loading='lazy'
                  placeholder='blurred'
                />
              </Box>
            )}
            <Heading
              level="1"
              textAlign={screenSize !== 'small' ? 'start' : 'center'}
            >
              A little more about me
            </Heading>
            <Box
              css={`
                font-size: 1.05rem;
                font-weight: 500;
              `}
            >
              <p>
                First things first, my name in Czech is
                Lukáš&nbsp;Horák&nbsp;[ˈlukaːʃ&nbsp;horaːk], but I'm fine with
                Lukas (it's easier).
              </p>

              <p>
                I'm a full-stack JavaScript developer with more than 6 years of
                experience working on websites and single page web apps. My go
                to programming language is JavaScript. When it comes to
                programming I enjoy the challenging stuff and always try to find
                the right solution for a problem.
              </p>
              <p>
                Scroll down to see more about my history, or what I like to do
                in my free time!
              </p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="full" pad="large">
        <Box
          align="stretch"
          direction="column"
          as="section"
          width="xlarge"
          margin={{ horizontal: 'auto' }}
        >
          <Box width="full" margin={{ horizontal: 'auto' }} align="center">
            <Heading level="1" textAlign="center">
              A look back into my history
            </Heading>
          </Box>
          <Box width="full" direction="column" pad="medium">
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" justify="center" basis="1/2">

                  <img
                    src={designerIllustration}
                    alt="My designer wannabe era"
                    height="250px"
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  Wannabe graphic designer
                </Heading>
                <p>
                  I have to admit, when I was at my high school, I had a huge
                  passion for design. I taught myself how to do bitmap graphics
                  in Photoshop, then moved to vector graphics in Illustrator.
                </p>
                <p>
                  I've started designing whatever came to my mind, mostly silly
                  background pictures for mine, or my friend's computers, it was
                  a fun time. After designing dozens of background images, I've
                  decided it was time to move on to something more challenging,
                  so I've started toying with logos and logotypes, and print
                  designs. Logos are quite interesting to work on. Taking a name
                  and trying to come up with something smart that would
                  represent the niche market of the brand and be memorable at
                  the same time was pretty hard, but I loved it! I participated
                  in couple of logo design tenders, but I was never successful.
                  Well, tough luck, moving on...
                </p>
                <p>
                  After print designs, I've started toying with designing for
                  web, creating user interfaces, and somewhere around that time
                  I've decided that not only I want to design those UIs, but I
                  want to make them come alive.
                </p>
                <p>
                  Sidenote: Some of my design work can be found on{' '}
                  <Anchor
                    href="https://www.behance.net/lukashorak"
                    target="_blank"
                    rel="noopener"
                    alt="Design portfolio on Behance"
                    a11yTitle="Design portfolio on Behance"
                  >
                    Behance
                  </Anchor>
                  , but beware most of it is more than 6 years old.{' '}
                </p>
              </Box>
            </Box>
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row-reverse'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" justify="center" basis="1/2">

                  <img
                    src={coderIllustration}
                    alt="Web Developer Illustration"
                    height="250px"
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  The development times
                </Heading>
                <p>
                  By the end of my high school, where I've already played with
                  some HTML and CSS in my spare time (a little bit of jQuery as
                  well, of course), I was still convinced I'd be studying visual
                  design. But one day, an idea came to my mind: "Hmm, I do know
                  how to design..." (at least I thought I did at that time) "How
                  about I get better at something I'm not so good at? So if I
                  learn how to code, I could take my designs, code them, and
                  build websites and apps! How awesome!". So in a nutshell, this
                  is what led me to applying to Czech Technical University to
                  study information technologies, and I have to say that was one
                  of the best decisions I made in my life.
                </p>
                <p>
                  While at the university, I've studied broad range of topics in
                  computer science, from Assembler, through C, C++, operating
                  systems, shell, computer graphics, pretty much anything you
                  can imagine in a world of computer science. While I enjoyed
                  most of the subjects, I was always somehow drawn to web
                  development. So as the time went by, I've started digging deep
                  into web development, started programming in JavaScript in my
                  free time, did my fair portion of "Hello worlds" and to-do
                  lists and I knew this was what I wanted to do professionally
                  in the future. During my studies, I did couple of website
                  projects and later on landed my first full-time job as a web
                  developer for the biggest online media company in Czech
                  Republic.
                </p>
                <p></p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width="full"
        css={`
          background: ${getThemeColor('brand-dark-blue')};
          color: ${getThemeColor('brand-light-gray')};
        `}
        pad="large"
      >
        <Box
          align="stretch"
          direction="column"
          as="section"
          width="xlarge"
          margin={{ horizontal: 'auto' }}
        >
          <Box width="full" margin={{ horizontal: 'auto' }} align="center">
            <Heading level="1" textAlign="center">
              My coding hobbies
            </Heading>
          </Box>
          <Box width="full" direction="column" pad="medium">
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" basis="1/2">

                  <img
                    src={smartHomeIllustration}
                    alt="Smart Home Illustration"
                    height={screenSize === 'small' ? '150px' : '200px'}
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  Smart home
                </Heading>
                <p>
                  Building smart home and building home automations is one of my
                  favourite hobbies. Whatever I can automate, I go for it. I
                  have a home system based on open source technologies. The main
                  brain of the whole system at my home is{' '}
                  <Anchor
                    href="https://home-assistant.io/"
                    target="_blank"
                    rel="noopener"
                  >
                    Home Assistant
                  </Anchor>
                  , running on{' '}
                  <Anchor
                    href="https://www.amazon.com/gp/search/ref=as_li_qf_sp_sr_tl?ie=UTF8&tag=lhorak-20&keywords=Intel NUC&index=aps&camp=1789&creative=9325&linkCode=ur2&linkId=23106cb98202fd73636408698203e06f"
                    target="_blank"
                    rel="noopener"
                  >
                    Intel NUC
                  </Anchor>
                  . I have automated all the lights at my home, coffee machine,
                  electrical plugs, and other stuff, everything controllable by
                  voice. I'll cover more on this topic in the blog.
                </p>
              </Box>
            </Box>
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row-reverse'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" justify="center" basis="1/2">
                  <img
                    src={electronicEngineerIllustration}
                    alt="Electronic Engineer Illustration"
                    height="200px"
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  Microcontrollers, circuits, soldering, hacking, and making
                </Heading>
                <p>
                  When I have the time, I like to get my soldering iron out and
                  try to put some stuff together. I have to say that I'm a
                  complete beginner in this, but nowadays, with internet full of
                  tutorials, it's pretty easy to put stuff together. Except for
                  lighting up some LEDs attached to breadboard, I did some IOT
                  projects like PIR movement sensors, Temperature sensors,
                  individually controllable LED strips and more. Most of those
                  are based on{' '}
                  <Anchor
                    href="https://amzn.to/3az0VBb"
                    target="_blank"
                    rel="noopener"
                  >
                    ESP8266
                  </Anchor>{' '}
                  or{' '}
                  <Anchor
                    href="https://www.amazon.com/gp/search/ref=as_li_qf_sp_sr_tl?ie=UTF8&tag=lhorak-20&keywords=wemos d1 mini&index=aps&camp=1789&creative=9325&linkCode=ur2&linkId=fc538a7ae3ddc61fcd93f41a63b32fda"
                    target="_blank"
                    rel="noopener"
                  >
                    Wemos D1
                  </Anchor>{' '}
                  microcontrollers, that enable me to connect those devices via
                  wifi and add them to my home automation heaven! But more on
                  that in the upcoming <GatsbyLink to="/blog/">Blog</GatsbyLink>
                  .
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="full" pad="large">
        <Box
          align="stretch"
          direction="column"
          as="section"
          width="xlarge"
          margin={{ horizontal: 'auto' }}
        >
          <Box width="full" margin={{ horizontal: 'auto' }} align="center">
            <Heading level="1" textAlign="center">
              When I go offline...
            </Heading>
          </Box>
          <Box width="full" direction="column" pad="medium">
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" justify="center" basis="1/2">

                  <img
                    src={kiteboardingIllustration}
                    alt="Kiteboarder Illustration"
                    height="200px"
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  Boarding of any kind
                </Heading>
                <p>
                  I've been skiing since I was a little kid and later on I've
                  switched to snowboarding, which I really dig. But lately I've
                  tried a new sport, and I think the picture above gives a
                  little hint about what sport it is. Yep, I've started
                  kiteboarding and wow, I have to say I've never been so much
                  into any sport like kiteboarding. The freedom on the water,
                  with the wind puling you across the sea, it's simply amazing.
                  If you're into kiteboarding, or snowboarding, drop me a line
                  about some amazing places to visit! By the way if you're
                  thinking about trying kiteboarding I have to recommend{' '}
                  <Anchor
                    href="https://wetskillz.com/"
                    target="_blank"
                    rel="noopener"
                    alt="WetSkillz Kiteboarding School, Rhodes, Greece"
                    a11yTitle="WetSkillz Kiteboarding School, Rhodes, Greece"
                  >
                    WetSkillz Kite School
                  </Anchor>{' '}
                  in Rhodes, Greece! Those guys are amazing!
                </p>
              </Box>
            </Box>
            <Box
              width="full"
              direction={screenSize === 'small' ? 'column' : 'row-reverse'}
              margin={{ vertical: '0', horizontal: 'auto' }}
              pad="small"
            >
              <Box alignContent="center" basis="1/2">

                  <img
                    src={travelIllustration}
                    alt="Traveling Illustration"
                    height="200px"
                    css={`
                      margin: auto;
                    `}
                  />
                
              </Box>
              <Box basis="1/2">
                <Heading
                  level="2"
                  textAlign={screenSize === 'small' ? 'center' : 'start'}
                >
                  Traveling and taking pics
                </Heading>
                <p>
                  Apart from sliding on different kinds of boards, my other
                  passion is traveling. I'm not really into laying on a beach
                  for couple of days doing nothing, I much prefer to either
                  explore the nature, or connect the traveling with sport
                  activities (like snowboarding or kiteboarding). I love the
                  Austrian and Italian Alps, and my new favourite place is
                  Rhodes, Greece. To see some of the most beautiful nature on
                  earth, I'd suggest Iceland to anyone, it's beautiful out
                  there. Anyway, ahead of me, there is a long list of places I'd
                  like to visit.
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        width="full"
        css={`
          background: ${getThemeColor('brand')};
        `}
        pad="large"
        align="center"
      >
        <Heading level="2" textAlign="center">
          Would you like to know more about my professional experience?
        </Heading>
        <Button
          color={getThemeColor('brand-dark-blue')}
          label="Have a look at my resume page"
          css={`
            font-weight: 700;
            padding: 1rem 2rem;
            background: ${getThemeColor('brand-dark-blue')};
            color: ${getThemeColor('brand-light-gray')};
          `}
          onClick={() => navigate('/resume/')}
        />
      </Box>
    </Layout>
  );
};

export default AboutMePage;
